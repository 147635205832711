import React, { useState } from 'react';
import Brand from '../assets/img/logo.png';
import '../assets/css/Header.css';

function Header() {
    // Zustand für das Burger-Menü, ob es offen oder geschlossen ist
    const [menuOpen, setMenuOpen] = useState(false);

    // Funktion zum Umschalten des Menüs
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header>
            <div className='header-wrapper'>
                <a href='/'><img className="brand_logo" src={Brand} alt="mybabybuddy.com Logo" /></a>

                {/* Burger-Menü Button */}
                <div className={`burger-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                {/* Navigation, die je nach Zustand (offen oder geschlossen) angezeigt wird */}
                <ul className={menuOpen ? 'open' : ''}>
                    <li>
                        <a href='/'>Home</a>
                    </li>
                    <li>
                        <a href='/products'>Produkte</a>
                    </li>
                    <li>
                        <a href='/about'>Über Uns</a>
                    </li>
                    <li>
                        <a href='/contact'>Kontakt</a>
                    </li>
                    <li>
                        <a href='/faq'>FAQ</a>
                    </li>
                </ul>
            </div>
        </header>
    );
}

export default Header;
