import React from 'react';
import '../assets/css/About.css';
import Goal from '../assets/img/goal.png';
import Doing from '../assets/img/doing.png';
import Save from '../assets/img/savedata.png';
import Contact from '../assets/img/contact.png';

function About() {
    return (
        <div className='about'>
            <h1>Über uns</h1>
            <div className='about-box'>
                <div className='about-text'>
                    <h2>Unsere Mission</h2>
                    <p>Unsere Mission ist es, eine benutzerfreundliche und umfassende Lösung zu schaffen, die dir hilft, die täglichen Bedürfnisse und Entwicklungen deines Babys effizient zu verwalten. Wir verstehen, dass die frühen Jahre eines Kindes besonders wichtig sind, und möchten dir mit unseren Produkten die Werkzeuge an die Hand geben, die du benötigst, um diese Zeit noch besser zu gestalten.</p>
                </div>

                <div className='about-images'>
                    <img src={Goal} alt="Goal" />
                </div>
            </div>
            <div className='about-box'>
                <div className='about-images'>
                    <img src={Doing} alt="Teamwork" />
                </div>
                <div className='about-text'>
                    <h2>Was wir tun</h2>
                    <p>Wir von mybabybuddy.com bieten dir eine Vielzahl von Produkten, die speziell entwickelt wurden, um deinen Alltag als Elternteil zu erleichtern:</p>

                    <h3>Die MyBabyCare App</h3>
                    <ul>
                        <li><strong>Fütterungsprotokoll:</strong> Verfolge die Fütterungszeiten und -mengen deines Babys einfach und präzise.</li>
                        <li><strong>Geburtsdaten:</strong> Speichere wichtige Informationen wie Geburtsdatum, -zeit, Gewicht und Größe.</li>
                        <li><strong>Entwicklungsprotokoll:</strong> Dokumentiere wichtige Meilensteine und beobachte die Fortschritte deines Babys.</li>
                        <li><strong>Gesundheits- und Pflegeeinträge:</strong> Halte Impfungen, Arztbesuche und besondere Pflegebedürfnisse fest.</li>
                        <li><strong>Erinnerungen und Benachrichtigungen:</strong> Setze Erinnerungen für wichtige Aufgaben und Termine.</li>
                        <li><strong>Datensicherung und Wiederherstellung:</strong> Deine Daten sind sicher und können bei Bedarf wiederhergestellt werden.</li>
                    </ul>
                </div>
            </div>
            <div className='about-box'>
                <div className='about-text'>
                    <h2>Unser Engagement für Datenschutz</h2>
                    <p>Deine Privatsphäre ist uns sehr wichtig. Wir setzen modernste Sicherheitsmaßnahmen ein, um sicherzustellen, dass deine Daten sicher und geschützt sind. Unsere Datenschutzerklärung gibt dir einen detaillierten Überblick über unsere Datenschutzpraktiken.</p>
                </div>
                <div className='about-images'>
                    <img src={Save} alt="Teamwork" />
                </div>
            </div>
            <div className='about-box'>
                <div className='about-images'>
                    <img src={Contact} alt="Teamwork" />
                </div>
                <div className='about-text'>
                    <h2>Kontakt</h2>
                    <p>Bei Fragen oder Feedback stehen wir dir jederzeit zur Verfügung. Du kannst uns über unsere Website oder per E-Mail kontaktieren. Wir freuen uns darauf, von dir zu hören!</p>
                </div>
            </div>
        </div>
    );
}

export default About;
