import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './sites/Home';
import Products from './sites/Products';
import About from './sites/About';
import Contact from './sites/Contact';
import FAQ from './sites/FAQ';
import Imprint from './sites/Imprint';
import Privacy from './sites/PrivacyPolicy';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <div className='wrapper'>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<Products />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/imprint" element={<Imprint />} />
              <Route path="/privacypolicy" element={<Privacy />} />
            </Routes>
          </div>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
