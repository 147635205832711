import React from 'react';
import '../assets/css/PrivacyPolicy.css';

function PrivacyPolicy() {
    return (
        <div className='privacy'>
            <h1>Datenschutzerklärung</h1>

            <h2>1. Einleitung</h2>

            <p>Willkommen bei mybabybuddy.com. Der Schutz Ihrer Daten und Ihre Privatsphäre sind uns sehr wichtig. Diese Datenschutzerklärung erläutert, wie wir Ihre personenbezogenen Daten erheben, verwenden, speichern und schützen, wenn Sie unsere Webseite oder unsere mobile Anwendung nutzen.</p>

            <h2>2. Verantwortlicher</h2>

            <p>Verantwortlicher für die Verarbeitung Ihrer personenbezogenen Daten ist:</p>

            <p>
                Ralf Glende<br />
                Gartenstraße 10A<br />
                41747 Viersen<br />
                contact@mybabybuddy.com<br />
            </p>

            <h2>3. Erhebung und Verwendung personenbezogener Daten</h2>

            <p>Wir erheben und verwenden personenbezogene Daten nur, soweit dies für die Nutzung der App erforderlich ist. Hierzu gehören:</p>
            <ul>
                <li>Registrierungsdaten: Wenn Sie sich registrieren, erfassen wir Ihre E-Mail-Adresse und möglicherweise andere Kontaktdaten.</li>
                <li>Profilinformationen: Dazu gehören Vorname, Nachname, Geburtsdatum, Adresse und andere von Ihnen bereitgestellte Informationen.</li>
                <li>Nutzungsdaten: Wir erfassen Daten zur Nutzung der App, wie z.B. Ihre Interaktionen, Geräteeinstellungen, IP-Adresse und Zugriffszeiten.</li>
                <li>Kommunikationsdaten: Wenn Sie uns kontaktieren, erfassen wir die Inhalte Ihrer Nachrichten und Ihre Kontaktdaten.</li>
            </ul>
            <h2>4. Zwecke der Datenverarbeitung</h2>

            <p>Wir verwenden Ihre Daten für folgende Zwecke:</p>
            <ul>
                <li>Bereitstellung und Verwaltung Ihres Benutzerkontos.</li>
                <li>Verbesserung und Anpassung der App an Ihre Bedürfnisse.</li>
                <li>Kommunikation mit Ihnen, einschließlich des Versands von Benachrichtigungen und Updates.</li>
                <li>Erfüllung rechtlicher Verpflichtungen und Schutz unserer Rechte.</li>
            </ul>

            <h2>5. Weitergabe von Daten</h2>

            <p>Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter, es sei denn:</p>
            <ul>
                <li>Sie haben Ihre ausdrückliche Zustimmung dazu gegeben.</li>
                <li>Es ist erforderlich, um gesetzliche Verpflichtungen zu erfüllen oder rechtliche Ansprüche durchzusetzen.</li>
                <li>Es erfolgt eine Weitergabe an Dienstleister, die in unserem Auftrag handeln und unsere App unterstützen, unter der Voraussetzung, dass sie angemessene Sicherheitsmaßnahmen zum Schutz Ihrer Daten treffen.</li>
            </ul>
            <h2>6. Datensicherheit</h2>

            <p>Wir treffen angemessene technische und organisatorische Maßnahmen, um Ihre Daten vor Verlust, Missbrauch und unbefugtem Zugriff zu schützen. Wir speichern Ihre Daten nur so lange, wie es für die Erfüllung der Zwecke erforderlich ist, für die sie erhoben wurden.</p>

            <h2>7. Ihre Rechte</h2>

            <p>Sie haben das Recht, jederzeit auf Ihre personenbezogenen Daten zuzugreifen, sie zu berichtigen oder zu löschen. Sie können auch der Verarbeitung Ihrer Daten widersprechen oder eine Einschränkung der Verarbeitung verlangen. Wenn Sie Fragen oder Anliegen zu Ihren Rechten haben, wenden Sie sich bitte an uns.</p>

            <h2>8. Änderungen dieser Datenschutzerklärung</h2>

            <p>Wir behalten uns vor, diese Datenschutzerklärung gelegentlich zu aktualisieren. Änderungen werden durch Veröffentlichung der neuen Datenschutzerklärung auf unserer Website oder in der App wirksam. Bitte überprüfen Sie regelmäßig unsere Datenschutzerklärung, um über Änderungen informiert zu bleiben.</p>

            <h2>9. Kontakt</h2>

            <p>Wenn Sie Fragen zu dieser Datenschutzerklärung oder zur Verarbeitung Ihrer personenbezogenen Daten haben, kontaktieren Sie uns bitte unter:</p>
            <p>
                Ralf Glende<br />
                Gartenstraße 10A<br />
                41747 Viersen<br />
                contact@mybabybuddy.com<br />
            </p>
        </div>
    );
}

export default PrivacyPolicy;

