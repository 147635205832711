import React from 'react';
import '../assets/css/Home.css';

function Home() {
    return (
        <div className='home'>
            <div className='landing-box'>
                <h1>Dein digitaler<br />Begleiter für dich<br />und dein Baby</h1>
                <p>mybabybuddy.com hilft dir, die richtigen Entscheidungen<br/>zu treffen und deine Familie gesund zu halten.<br/>Bereit für eine sichere, gut informierte Reise durch das Elternsein?</p>
                <a className="btn" href="/products">Jetzt Produkte entdecken</a>
            </div>
        </div>
    );
}

export default Home;