import React from 'react';
import '../assets/css/Footer.css';

function Footer() {
  return (
    <footer>
      <ul>
        <li>
          <a href="/imprint">Impressum</a>
        </li>
        <li>
          <a href="/privacypolicy">Datenschutz</a>
        </li>
      </ul>
      <p>© 2024 mybabybuddy.com. Alle Rechte vorbehalten.</p>
    </footer>
  );
}

export default Footer;
