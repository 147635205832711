import React, { useState } from 'react';
import '../assets/css/Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/sendMail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                alert('Nachricht erfolgreich gesendet!');
                setFormData({ name: '', email: '', message: '' });
            } else {
                alert('Fehler beim Senden der Nachricht.');
            }
        } catch (error) {
            console.error('Fehler:', error);
            alert('Es gab ein Problem beim Senden der Nachricht.');
        }
    };


    return (
        <form onSubmit={handleSubmit} className='contact'>
            <label>
                Dein Name:
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </label>
            <label>
                Deine E-Mail Adresse:
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </label>
            <label>
                Deine Nachricht:
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                />
            </label>
            <button className="btn" type="submit">Absenden</button>
        </form>
    );
};

export default Contact;
