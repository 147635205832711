import React from 'react';
import '../assets/css/Products.css';
import ProductMyBabyCare from '../assets/img/mybabycare_example.png';
import QRCode from "react-qr-code";

function Products() {
    return (
        <div className='products'>
            <h1>Produkte</h1>
            <div className='product'>
                <div className='product-info'>
                    <h2>MyBabyCare</h2>
                    <p>Die BabyCare App ist die ultimative Lösung für alle Eltern, die eine einfache,
                        effektive und umfassende Methode suchen, um die täglichen Bedürfnisse
                        und Entwicklungen ihres Babys zu verwalten. Egal, ob du gerade erst ein
                        neues Baby bekommen hast oder bereits Erfahrung als Elternteil hast – diese
                        App bietet dir alle Werkzeuge, die du benötigst, um das Beste für dein Baby
                        zu gewährleisten.</p>
                    <div className='to-app-box'>
                        <a href="https://apps.apple.com/app/mybabycare-app/id6670793215">
                            <div className='btn to-app'>
                                <p>Direkt zu iOS</p>
                                <div className="qr-code" style={{ height: "auto", margin: "0 auto", width: 125 }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value='https://apps.apple.com/app/mybabycare-app/id6670793215'
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                            </div>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.mybabybuddy.baby_care_app">
                            <div className='btn to-app'>
                                <p>Direkt zu Android</p>
                                <div className="qr-code" style={{height: "auto", margin: "0 auto", width: 125 }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value='https://play.google.com/store/apps/details?id=com.mybabybuddy.baby_care_app'
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <img className='product-example' src={ProductMyBabyCare} alt="MyBabyCare Beispiel Foto" />
            </div>
        </div>
    );
}

export default Products;